import { GenericModalProps } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';

export type TUseConfirmSellModalLogicArgs = {
  count: number;
  handleClose: () => void;
  open: boolean;
  submitHandler: () => void;
  volume: number;
};

export const useConfirmSellModalLogic = ({
  count,
  handleClose,
  open,
  submitHandler,
  volume,
}: TUseConfirmSellModalLogicArgs): GenericModalProps => {
  const { t } = useTranslation();
  return {
    open,
    title: t('certificate.modals.confirmSell.title'),
    text: t('certificate.modals.confirmSell.text', {
      count,
      volume,
    }),
    buttons: [
      {
        label: t('general.buttons.cancel'),
        onClick: () => handleClose(),
        variant: 'outlined',
      },
      {
        label: t('general.buttons.confirm'),
        onClick: () => {
          handleClose();
          submitHandler();
        },
      },
    ],
    dialogProps: { maxWidth: 'sm' },
  };
};
