import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { IRecImportForm } from './types';

export const useImportIRecFormLogic: IRecImportForm = () => {
  const { t } = useTranslation();

  return {
    initialValues: {
      file: '',
    },
    validationSchema: yup.object({
      file: yup
        .mixed()
        .required()
        .label(t('organization.importIRec.file')),
    }),
    fields: [
      {
        name: 'file',
        label: t('organization.importIRec.file'),
        required: true,
        type: 'file',
        inputProps: { ['data-cy']: 'file' },
      },
    ],
    buttonText: t('organization.importIRec.submitButton'),
  };
};
