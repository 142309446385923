export const fuelToDeviceTypesMatching = {
  ES200: ['TC210', 'TC220'],
  ES100: ['TC110', 'TC120', 'TC130', 'TC140', 'TC150'],
  ES300: ['TC310', 'TC320', 'TC330'],
  ES410: ['TC421', 'TC422', 'TC423', 'TC424'],
  ES420: ['TC421', 'TC422', 'TC423', 'TC424'],
  ES430: ['TC421', 'TC422', 'TC423', 'TC424'],
  ES440: ['TC421', 'TC422', 'TC423', 'TC424'],
  ES810: [
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES820: [
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES830: [
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES840: [
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES510: [
    'TC441',
    'TC442',
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES520: [
    'TC441',
    'TC442',
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES530: [
    'TC441',
    'TC442',
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES540: [
    'TC441',
    'TC442',
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES550: [
    'TC441',
    'TC442',
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES560: [
    'TC441',
    'TC442',
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES570: [
    'TC441',
    'TC442',
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES610: ['TC441', 'TC442'],
  ES620: ['TC441', 'TC442'],
  ES630: ['TC441', 'TC442'],
  ES650: ['TC441', 'TC442'],
  ES660: ['TC441', 'TC442'],
  ES670: ['TC441', 'TC442'],
  ES680: ['TC441', 'TC442'],
  ES710: [
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
  ],
  ES720: [
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
  ],
  ES730: [
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
  ],
  ES740: [
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
  ],
  ES910: [
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
  ],
  ES920: [
    'TC441',
    'TC442',
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES930: [
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
  ],
  ES940: [
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
  ],
  ES950: [
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
  ],
  ES960: [
    'TC441',
    'TC442',
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES970: [
    'TC441',
    'TC442',
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES980: [
    'TC441',
    'TC442',
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
  ES990: [
    'TC441',
    'TC442',
    'TC410',
    'TC411',
    'TC431',
    'TC432',
    'TC451',
    'TC452',
    'TC471',
    'TC472',
    'TC421',
    'TC422',
    'TC423',
    'TC424',
    'TC461',
    'TC462',
    'TC481',
    'TC482',
    'TC491',
    'TC492',
  ],
};
