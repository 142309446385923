import { useTranslation } from 'react-i18next';
import { EnergyFormatter, formatDate } from '@energyweb/origin-ui-utils';
import { PublicOrder } from '@energyweb/exchange-irec-react-query-client';
import { TUseLogicRecentTrades, TFormatRecentTradesData } from './types';
import { useNavigate } from 'react-router';
import { useCallback } from 'react';

const formatRecentTradesData: TFormatRecentTradesData = ({ t, trades }) => {
  return (
    trades?.map((trade) => {
      // console.log(trade);
      return {
        id: trade.id,
        date: formatDate(trade.date),
        buyer: trade.buyer,
        seller: trade.seller,
        volume: EnergyFormatter.format(trade.volume * 1000000),
      };
    }) || []
  );
};

export const useLogicRecentTrades: TUseLogicRecentTrades = ({ trades, loading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigateToTradeView = useCallback(
    async (id: PublicOrder['id']) => {
      navigate(
        `/exchange/trade-view/${id}`,
        { state: { prevPath: location.pathname }},
      );
    },
    [navigate]
  );

  return {
    tableTitle: t('exchange.recentTrades.tableTitle'),
    tableTitleProps: { variant: 'h5' },
    header: {
      date: t('exchange.trade.date'),
      buyer: t('exchange.trade.buyer'),
      volume: `${t('exchange.trade.volume')} (${
        EnergyFormatter.displayUnit
      })`,
      seller: t('exchange.trade.seller'),
    },
    pageSize: 10,
    loading: loading,
    data: formatRecentTradesData({
      t,
      trades,
    }),
    onRowClick: handleNavigateToTradeView,
  };
};
