import { InvitationDTO, OrganizationInvitationStatus, useInvitationControllerUpdateInvitation, User } from '@energyweb/origin-backend-react-query-client';
import { TableActionData, TableComponentProps } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { DeleteOutline } from '@mui/icons-material';
import {
  useOrganizationMemberRemove,
} from '@energyweb/origin-ui-organization-data';

export const useSentInvitationsTableLogic = (
  invitations: InvitationDTO[],
  loading: boolean
): TableComponentProps<InvitationDTO['id']> => {
  const { t } = useTranslation();

  const {
    mutate,
  } = useInvitationControllerUpdateInvitation();
  // useOrganizationMemberRemove();

  const declineHandler = (rowId: number) => {
    const { id } = invitations[rowId - 1];
    mutate(
      { id: id.toString(), status: OrganizationInvitationStatus.Rejected },
    );
  };

  const actions: TableActionData<User['id']>[] = [
    {
      icon: '', // <DeleteOutline data-cy="removeMember" />,
      name: t('general.buttons.decline'),
      onClick: declineHandler,
    },
  ];
  
  const prepareSentInvitation = (invite: InvitationDTO) => ({
    id: invite.id,
    email: invite.email,
    status: invite.status,
    actions,
  });
  
  return {
    header: {
      email: t('organization.invitations.email'),
      status: t('organization.invitations.status'),
      actions: '',
    },
    loading,
    pageSize: 5,
    tableTitle: t('organization.invitations.sentTableTitle'),
    data: invitations?.map((invite) => prepareSentInvitation(invite)) ?? [],
  };
};
