import { EnergyTypeEnum } from '@energyweb/origin-ui-utils';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './StockImages.styles';

type TImageType = FC<React.ImgHTMLAttributes<HTMLImageElement>>;

const EnergyImage = (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.imageWrapper}>
        <caption className={classes.imageCaption}>
          {t('error.imageNotFound')}
        </caption>
        <img className={classes.image} {...props} />
      </div>
    </div>
  );
}

export const Gaseous = () => (
  <EnergyImage src="https://images.pexels.com/photos/266896/pexels-photo-266896.jpeg" />
)

export const Hydro = () => (
  <EnergyImage src="https://images.pexels.com/photos/355919/pexels-photo-355919.jpeg" />
)

export const Liquid = () => (
  <EnergyImage src="https://images.pexels.com/photos/40784/drops-of-water-water-nature-liquid-40784.jpeg" />
)

export const Marine = () => (
  <EnergyImage src="https://images.pexels.com/photos/7925938/pexels-photo-7925938.jpeg" />
)

export const Solar = () => (
  <EnergyImage src="https://images.pexels.com/photos/159397/solar-panel-array-power-sun-electricity-159397.jpeg" />
)

export const Solid = () => (
  <EnergyImage src="https://images.pexels.com/photos/167698/pexels-photo-167698.jpeg" />
)

export const Thermal = () => (
  <EnergyImage src="https://images.pexels.com/photos/119563/pexels-photo-119563.png" />
)

export const Wind = () => (
  <EnergyImage src="https://images.pexels.com/photos/414837/pexels-photo-414837.jpeg" />
)

const images: {
  [k: string]: TImageType;
} = {
  [EnergyTypeEnum.WIND]: Wind,
  [EnergyTypeEnum.SOLAR]: Solar,
  [EnergyTypeEnum.HYDRO]: Hydro,
  [EnergyTypeEnum.MARINE_TIDAL]: Marine,
  [EnergyTypeEnum.MARINE_WAVE]: Marine,
  [EnergyTypeEnum.MARINE_CURRENT]: Marine,
  [EnergyTypeEnum.MARINE_VERTICAL]: Marine,
  [EnergyTypeEnum.RENEWABLE_HEAT]: Thermal,
  [EnergyTypeEnum.BIOMASS_SOLID]: Solid,
  [EnergyTypeEnum.BIOMASS_LIQUID]: Liquid,
  [EnergyTypeEnum.BIOGAS]: Gaseous,
  [EnergyTypeEnum.CO_FIRED_WITH_FOSSIL]: Solid,
};

export const getEnergyTypeStockImage = (type: EnergyTypeEnum, selected = false) => {
  const fuelType = type || EnergyTypeEnum.SOLAR;
  return images[fuelType.toLowerCase()];
};

