import {
  PublicOrder,
  useTradeControllerGetAllPublic,
} from '@energyweb/exchange-irec-react-query-client';

export const useApiRecentTrades = () => {
  const refetchInterval = 10000;
  const { data, isLoading } = useTradeControllerGetAllPublic();
  const recentTrades = data ?? ([] as PublicOrder[]);

  return { recentTrades, isLoading };
};
