import { useTranslation } from 'react-i18next';
import { EnergyFormatter, formatDate } from '@energyweb/origin-ui-utils';
import { TUseLogicTrade, TFormatTradeData } from './types';
import { useAllDeviceFuelTypes, useAllDeviceTypes } from '@energyweb/origin-ui-exchange-data';
import { Countries } from '@energyweb/utils-general';
import { commify } from '@ethersproject/units';

export const formatPrice = (price: number) => {
  const [whole, frac] = price.toFixed(2).split('.')
  return `$${commify(whole)}.${frac}`;
}

const formatTradeData: TFormatTradeData = ({ deviceTypes, fuelTypes, loading, myTrade, t, trade }) => {
  // console.log(trade);
  if (!trade) {
    return null
  }
  return {
    id: trade.id,
    date: formatDate(trade.date),
    tableDataDevice: {
      data: [{
        id: trade.id,
        device: trade.device.name,
        country: Countries.find((country) => country.code === trade.device.country).name,
        fuel: fuelTypes?.find((fuelType) => fuelType.code === trade.device.fuel)?.name || trade.device.fuel,
        technology: deviceTypes?.find((deviceType) => deviceType.code === trade.device.technology)?.name || trade.device.technology,
        capacity: trade.device.capacity,
        commissionDate: formatDate(trade.device.commissionDate, false, 'GMT'),
        supported: trade.device.supported,
        periodStart: formatDate(trade.device.productionStart, false, 'GMT'),
        periodEnd: formatDate(trade.device.productionEnd, false, 'GMT'),
        issuer: trade.device.issuerName,
      }],
      header: {
        device: t('exchange.trade.device'),
        country: t('exchange.trade.country'),
        fuel: t('exchange.trade.fuel'),
        technology: t('exchange.trade.technology'),
        capacity: `${t('exchange.trade.capacity')} (${
          EnergyFormatter.displayUnit
        })`,
        commissionDate: t('exchange.trade.commissionDate'),
        supported: t('exchange.trade.supported'),
        periodStart: t('exchange.trade.periodStart'),
        periodEnd: t('exchange.trade.periodEnd'),
        issuer: t('exchange.trade.issuer'),
      },
      hideFooter: true,
      loading,
      tableTitle: t('exchange.trade.tableTitle'),
      tableTitleProps: { textAlign: 'center', variant: 'h6' },
    },
    tableDataTrade: myTrade ? {
      data: [{
        id: trade.id,
        source: trade.seller,
        destination: trade.buyer,
        totalVolume: EnergyFormatter.format(trade.volume * 1000000),
        unitPrice: formatPrice(myTrade.price / 100),
        totalPrice: formatPrice(myTrade.price / 100 * trade.volume),
        periodStart: formatDate(trade.device.productionStart, false, 'GMT'),
        periodEnd: formatDate(trade.device.productionEnd, false, 'GMT'),
      }],
      header: {
        source: t('exchange.trade.source'),
        destination: t('exchange.trade.destination'),
        totalVolume: t('exchange.trade.totalVolume'),
        unitPrice: t('exchange.trade.unitPrice'),
        totalPrice: t('exchange.trade.totalPrice'),
        periodStart: t('exchange.trade.periodStart'),
        periodEnd: t('exchange.trade.periodEnd'),
      },
      hideFooter: true,
      loading,
      tableTitle: '',
    } : null,
  };
};

export const useLogicTrade: TUseLogicTrade = ({ loading, myTrade, prevPath, trade }) => {
  const backLabel = /my-trades/.test(prevPath) && 'exchange.trade.backButton'
    || /transaction-bulletin/.test(prevPath) && 'exchange.recentTrades.backButton'
    || 'general.buttons.back';

  const { allTypes: deviceTypes, isLoading: areDeviceTypesLoading } =
    useAllDeviceTypes();
  const { allTypes: fuelTypes, isLoading: areFuelTypesLoading } =
    useAllDeviceFuelTypes();
  const { t } = useTranslation();

  const { buyer, seller, volume = 0 } = trade || {}

  return {
    backLabel,
    data: formatTradeData({ deviceTypes, fuelTypes, loading, myTrade, t, trade }),
    description: t('exchange.trade.description', {
      buyer,
      seller,
      volume: EnergyFormatter.format(volume * 1000000),
    }),
    labels: {
      id: t('exchange.trade.id'),
      date: t('exchange.trade.date'),
    },
    loading: areDeviceTypesLoading || areFuelTypesLoading,
  };
};
