import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 20,
  },
  image: {
    height: 400,
  },
  imageCaption: {
    color: 'white',
    fontSize: 34,
    position: 'absolute',
    right: '30%',
    textShadow: '2px 2px #333',
    top: '40%',
    transform: 'rotate(-37deg)',
  },
  imageWrapper: {
    position: 'relative',
  },
}));
