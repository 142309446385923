import { useTranslation } from 'react-i18next';
import { EnergyFormatter, formatDate } from '@energyweb/origin-ui-utils';
import { TUseLogicMyTrades, TFormatMyTradesData } from './types';
import { useCallback } from 'react';
import { PublicOrder } from '@energyweb/exchange-irec-react-query-client';
import { useNavigate } from 'react-router';
import { formatPrice } from '../trade';

const formatMyTradesData: TFormatMyTradesData = ({ t, trades }) => {
  const buyText = t('exchange.myTrades.buy');
  const sellText = t('exchange.myTrades.sell');

  return (
    trades?.map((trade) => {
      // console.log(trade);
      const price = trade.price / 100;
      const totalPrice = parseFloat(trade.volume) * price / 1000000;

      return {
        id: trade.id,
        date: formatDate(trade.created),
        side: trade.bidId ? buyText : sellText,
        volume: EnergyFormatter.format(trade.volume),
        price: formatPrice(price),
        total: formatPrice(totalPrice),
      };
    }) || []
  );
};

export const useLogicMyTrades: TUseLogicMyTrades = ({ trades, loading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigateToTradeView = useCallback(
    async (id: PublicOrder['id']) => {
      navigate(
        `/exchange/trade-view/${id}`,
        { state: { prevPath: location.pathname }},
      );
    },
    [navigate]
  );

  return {
    data: formatMyTradesData({
      t,
      trades,
    }),
    header: {
      date: t('exchange.myTrades.date'),
      side: t('exchange.myTrades.side'),
      volume: `${t('exchange.myTrades.volume')} (${
        EnergyFormatter.displayUnit
      })`,
      price: t('exchange.myTrades.price'),
      total: t('exchange.myTrades.total'),
    },
    loading: loading,
    onRowClick: handleNavigateToTradeView,
    pageSize: 10,
    tableTitle: t('exchange.myTrades.tableTitle'),
    tableTitleProps: { variant: 'h5' }
  };
};
